<template>
    <div class="footer__site">
        <div class="container">
            <div class="footer__wrapper">
                 <div class="">
                    <div class="mb--sm--4 mb--md--6 d--sm--flex align-items--sm--center h5 font-weight--sm--500">
                        <CallIcon class="mr--sm--3"/>
                        <span>Liên hệ trình dược viên</span>
                    </div>
                    <div class="mb--sm--4 mb--md--6 d--sm--flex align-items--sm--center font-weight--sm--500 pl--sm--8" v-if="$store.state.auth.user.tdvName">
                        <span>{{$store.state.auth.user.tdvName}}</span>
                    </div>
                    <div class="mb--sm--4 mb--md--6 d--sm--flex align-items--sm--center font-weight--sm--500 pl--sm--8" v-if="$store.state.auth.user.tdvPhone">
                        <a class="d--sm--flex align-items--sm--center" :href="`tel:${$store.state.auth.user.tdvPhone}`" :title="$store.state.auth.user.tdvPhone">
                            <PhoneIcon class="mr--sm--3"/>
                            <span>{{$store.state.auth.user.tdvPhone}}</span>
                        </a>
                    </div>
                    <div class="mb--sm--4 mb--md--6 d--sm--flex align-items--sm--center font-weight--sm--500 pl--sm--8" v-if="$store.state.auth.user.tdvEmail">
                        <a class="d--sm--flex align-items--sm--center" :href="`malto:${$store.state.auth.user.tdvEmail}`" :title="$store.state.auth.user.tdvEmail">
                            <MailIcon class="mr--sm--3"/>
                            <span>{{$store.state.auth.user.tdvEmail}}</span>
                        </a>
                    </div>
                 </div>
                 <div class="">
                    <div class="mb--sm--4 mb--md--6">
                        <a class="pharmacy-information-card__action d--sm--flex align-items--sm--center" href="mailto:support@bostonpharma.com.vn" title="">
                            <EnvelopIcon class="mr--sm--3"/>
                            <span>support@bostonpharma.com.vn</span>
                        </a>
                    </div>
                    <div class="mb--sm--4 mb--md--6">
                        <a class="pharmacy-information-card__action d--sm--flex align-items--sm--center" href="https://www.facebook.com/BostonVN/" title="" target="_blank">
                            <FacebookIcon class="mr--sm--3"/>
                            <span>Boston Pharma</span>
                        </a>
                    </div>
                    <div>
                        <a class="pharmacy-information-card__action d--sm--flex align-items--sm--center" href="https://bostonpharma.com.vn" title="" target="_blank">
                            <WebsiteIcon class="mr--sm--3"/>
                            <span>https://bostonpharma.com.vn</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CallIcon from '@/assets/call.svg'
import MailIcon from '@/assets/mail-footer-icon.svg'
import PhoneIcon from '@/assets/phone-footer-icon.svg'
import EnvelopIcon from '@/assets/envelop-icon.svg'
import FacebookIcon from '@/assets/facebook-icon.svg'
import WebsiteIcon from '@/assets/website-icon.svg'
export default {
  components: {
    CallIcon,
    MailIcon,
    PhoneIcon,
    EnvelopIcon,
    FacebookIcon,
    WebsiteIcon
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss">
    @import "@/scss/respoinsive.scss";
    .footer {
        &__site {
            width: 100%;
            border-top: 1px solid #19958C;
            padding: 50px 0;
            margin-top: 50px;
            @include mobile() {
                display: none;
            }
        }
        &__wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
</style>
