<template>
    <div class="footer-nav-mobile" v-if="$store.state.footerMobile">
        <router-link to="/" class="footer-nav-mobile__item" exact-active-class="footer-nav-mobile__item--active">
            <BuyIcon class="mb--sm--1"/>
            <div>Mua hàng</div>
        </router-link>
        <router-link to="/promotion" class="footer-nav-mobile__item" active-class="footer-nav-mobile__item--active">
            <PromotionIcon class="mb--sm--1"/>
            <div>Khuyến mãi</div>
        </router-link>
        <router-link to="/report" class="footer-nav-mobile__item" active-class="footer-nav-mobile__item--active">
            <StatisticalIcon class="mb--sm--1"/>
            <div>Thống kê</div>
        </router-link>
        <router-link to="/customer" class="footer-nav-mobile__item" active-class="footer-nav-mobile__item--active">
            <PersonalIcon class="mb--sm--1"/>
            <div>Cá nhân</div>
        </router-link>
    </div>
</template>
<script>
import BuyIcon from '@/assets/buy-icon-footer-mobile.svg'
import PromotionIcon from '@/assets/promotion-icon-footer-mobile.svg'
import StatisticalIcon from '@/assets/statistical-icon-footer-mobile.svg'
import PersonalIcon from '@/assets/personal-icon-footer-mobile.svg'
export default {
  components: {
    BuyIcon,
    PromotionIcon,
    StatisticalIcon,
    PersonalIcon
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss">
    @import "@/scss/respoinsive";
    .footer-nav-mobile {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        border-top: solid 1px #B1B1B1;
        height: 80px;
        background-color: #ffffff;
        @include tablet() {
            display: none;
        }
        &__item {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #7E7E7E;
            cursor: pointer;
            font-size: 12px;
            text-decoration: none;
            &--active, &:hover {
                font-weight: 600;
                color: #003B35;
            }
            &:hover {
                opacity: 0.75;
            }
        }
    }
</style>
