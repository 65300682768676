<template>
  <div style="width: 100%">
    <div class="header-placeholder" :class="[isSticky ? 'header-placeholder--active' : '']"></div>
    <header ref="header" class="header__site" :class="[isSticky ? 'header__site--sticky' : '']">
      <div class="container">
        <div class="header__wrapper">
          <div class="header__logo">
            <router-link to="/" class="header__nav__action"><HeaderLogo /></router-link>
          </div>
          <div v-if="$store.state.headerMobile.back" class="mr--sm--4 forSP" v-on:click="$router.back()">
            <BackIcon />
          </div>
          <div class="h5 font-weight--sm--600 text--sm--center forSP" style="flex: 1" v-if="$store.state.headerMobile.heading && $store.state.headerMobile.headingText">{{$store.state.headerMobile.headingText}}</div>
          <div class="header__seach-box mr--sm--4 forSP" v-if="$store.state.headerMobile.search">
            <FinderIcon/>
            <input type="text" v-model="searchKey" v-on:keyup="searchProductOnChangeHandle" ref="mobileInputSearch" placeholder="Tìm kiếm sản phẩm" v-on:click="seachBoxMobileClickHandle">
          </div>
          <ul class="header__nav-mobile">
            <li class="header__nav-mobile__item" v-if="$store.state.headerMobile.notify">
              <router-link to="/notification" class="header__nav-mobile__action">
                <div class="cart-icon">
                  <div class="cart-count" v-if="notificationCount>0">{{notificationCount}}</div>
                  <NotifyIcon/>
                </div>
              </router-link>
            </li>
            <li class="header__nav-mobile__item" v-if="$store.state.headerMobile.cart">
              <router-link to="/cart" class="header__nav-mobile__action">
                <div class="cart-icon">
                  <div class="cart-count" v-if="cartCount>0">{{cartCount}}</div>
                  <CartIcon/>
                </div>
              </router-link>
            </li>
          </ul>
          <nav class="header__nav">
            <ul>
                <li class="header__nav__item">
                  <router-link to="/" class="header__nav__action">Mua hàng</router-link>
                </li>
                <li class="header__nav__item">
                  <router-link to="/promotion" class="header__nav__action">Khuyến mãi</router-link>
                </li>
                <li class="header__nav__item">
                  <router-link to="/report" class="header__nav__action">Thống kê</router-link>
                </li>
                <li class="header__nav__item">
                  <router-link to="/customer" class="header__nav__action">Cá nhân</router-link>
                </li>
                <li class="header__nav__item" >
                  <a href="#" title="" class="header__nav__action" v-on:click.prevent="showSeachBoxDesktopHandle">
                   <FinderIcon/>
                   </a>
                </li>
                <li class="header__nav__item mr--sm--4" >
                   <div class="cart-icon" v-on:click="showNotiModalHandle">
                    <div class="cart-count" v-if="notificationCount>0">{{notificationCount}}</div>
                    <NotifyIcon/>
                  </div>
                </li>
                <li class="header__nav__item" >
                  <router-link to="/cart">
                    <div class="cart-icon">
                      <div class="cart-count" v-if="cartCount>0">{{cartCount}}</div>
                      <CartIcon/>
                    </div>
                  </router-link>
                </li>
              </ul>
          </nav>
        </div>
      </div>
    </header>
    <el-dialog
      title="Tìm kiếm sản phẩm"
      :visible.sync="seachProdcutDialogVisible">
      <div>
        <HdInput
                v-model="searchKey"
                type="text"
                placeholder="Tìm kiếm"
                v-on:keyup="searchProductOnChangeHandle"
              >
                <template v-slot:icon><FinderIcon/></template>
              </HdInput>
              <div class="row">
          <div class="col col--sm--12 col--md--6 mb--sm--3 mb--md--6 flagSearchHeader" v-for="product in productsSearch" :key="product.productCode">
            <ProductCard
              :product="product"
            />
          </div>
          <infinite-loading @infinite="infiniteSearchHandler" v-if="(this.paginationSearch.total / this.paginationSearch.pageSize) > 1"><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
          <div class="col col--sm--12 d--sm--flex justify-content--sm--center align-items--sm--center flex-direction--sm--column" v-if="$_.isEmpty(productsSearch)">
            <div class="text--sm--center mt--sm--3 mt--md--9">Không có sản phẩm nào để hiển thị!</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="Thông báo"
      :visible.sync="notifycationDialogVisible">
      <div>
              <div class="row">
          <div class="col--sm--12" v-for="(notification, index) in notifications" :key="index">
                <div class="message" :class="[notification.isRead ? 'message--is-read' : '']" v-on:click="notificationClickHandle({sonumber: notification.sonumber, id: notification.id})">
                    <div class="marked-is-read d--sm--flex align-items--sm--center justify-content--sm--end" v-if="!notification.isRead"><span v-on:click="readNotificationClickHandle(notification.id)">Đánh dấu là đã đọc</span><CheckReadedIcon class="ml--sm--2"/></div>
                    <div class="h5 font-weight--sm--500 mb--sm--3" v-if="notification.title">{{notification.title}}</div>
                    <div class="mb--sm--3" v-if="notification.body">{{notification.body}}</div>
                    <div class="text--sm--right"><span v-if="notification.createDate && notification.createTime">{{$moment(notification.createDate.concat(notification.createTime), 'YYYYMMDDHHmmss').format('DD/MM/YYYY HH:mm')}}</span></div>
                </div>
            </div>
            <infinite-loading @infinite="infiniteHandler"><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
          <div class="col col--sm--12 d--sm--flex justify-content--sm--center align-items--sm--center flex-direction--sm--column" v-if="$_.isEmpty(notifications)">
            <div class="text--sm--center mt--sm--3 mt--md--9">Không có sản phẩm nào để hiển thị!</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <ButtonPhoneRing/>
  </div>
</template>

<script>
import HeaderLogo from '@/assets/header-logo.svg'
import CartIcon from '@/assets/cart-header-icon.svg'
import NotifyIcon from '@/assets/notify-icon.svg'
import FinderIcon from '@/assets/finder-icon-search-header-mobile.svg'
import HdInput from '@/components/HdInput'
import ProductCard from '@/components/ProductCard.vue'
import CheckReadedIcon from '@/assets/check-readed.svg'
import BackIcon from '@/assets/header-mobile-back-icon.svg'
import ButtonPhoneRing from '@/components/ButtonPhoneRing.vue'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'Header',
  components: {
    HeaderLogo,
    CartIcon,
    NotifyIcon,
    FinderIcon,
    BackIcon,
    HdInput,
    ProductCard,
    CheckReadedIcon,
    ButtonPhoneRing,
    InfiniteLoading
  },
  data () {
    return {
      isSeachLoading: false,
      isSticky: false,
      seachProdcutDialogVisible: false,
      notifycationDialogVisible: false
    }
  },
  methods: {
    infiniteSearchHandler: function ($state) {
      this.$store.commit('product/setSearchKey', this.$route.query.searchKey || '')
      // getTotalResults beffore to update pagination.
      if (this.paginationSearch.currentPage <= (this.paginationSearch.total / this.paginationSearch.pageSize)) {
        this.$store.dispatch('product/getProductsSearch').then(() => {
          $state.loaded()
          // Set curent page after load
          this.paginationSearch.currentPage += 1

          // Check if is last of pages.
          if (this.paginationSearch.currentPage >= (this.paginationSearch.total / this.paginationSearch.pageSize)) {
            $state.complete()
          }
        })
      } else {
        $state.complete()
      }
    },
    infiniteHandler: function ($state) {
      this.$store.dispatch('user/getTotalNotifications').then(() => {
        if (this.pagination.currentPage <= (this.pagination.total / this.pagination.pageSize)) {
          this.$store.dispatch('user/getNotification').then(() => {
            $state.loaded()
            // Set curent page after load
            this.pagination.currentPage += 1

            // Check if is last of pages.
            if (this.pagination.currentPage >= (this.pagination.total / this.pagination.pageSize)) {
              $state.complete()
            }
          })
        } else {
          $state.complete()
        }
      })
    },
    handleScroll () {
      if (window.scrollY > this.$refs.header.offsetTop + 116) {
        this.isSticky = true
      } else {
        this.isSticky = false
      }
    },
    showSeachBoxDesktopHandle: function () {
      this.seachProdcutDialogVisible = true
      this.$store.commit('product/setProductsSearch', [])
      // Seach product on mounted
      this.$store.commit('product/setSearchKey', this.$route.query.searchKey || '')
      this.$store.dispatch('product/getTotalProductsSearch')
    },
    readNotificationClickHandle: function (id) {
      this.$store.dispatch('user/readNotification', { id })
    },
    notificationClickHandle: function ({ id, sonumber }) {
      this.$store.dispatch('user/readNotification', { id }).then(() => {
        this.$store.dispatch('user/getNotification')
        this.$router.push(`/order-history/${sonumber}`).catch(() => {})
      })
    },
    showNotiModalHandle: function () {
      this.$store.dispatch('user/getNotification')
      this.notifycationDialogVisible = true
    },
    seachBoxMobileClickHandle: function () {
      if (this.$route.name !== 'Search') {
        this.$router.push({ name: 'Search', query: { searchKey: this.$route.query.searchKey || '' } }).catch(() => {})
      }
    },
    searchProductOnChangeHandle: function () {
      if (!this.isSeachLoading) {
        this.isSeachLoading = true
        this.$router.replace({ query: { searchKey: this.searchKey } })
        this.$store.commit('product/setSearchKey', this.$route.query.searchKey || '')
        // getTotalResults beffore to update pagination.
        this.$store.commit('product/setProductsSearch', [])
        this.paginationSearch.currentPage = 1
        this.$store.dispatch('product/getTotalProductsSearch').then(() => {
          this.$store.dispatch('product/getProductsSearch').then(() => {
            this.isSeachLoading = false
          }).catch(() => {
            this.isSeachLoading = false
          })
        }).catch(() => {
          this.isSeachLoading = false
        })
      }
    }
  },
  computed: {
    pagination: {
      get () {
        return this.$store.state.user.pagination
      },
      set (pagination) {
        this.$store.commit('user/setPagination', pagination)
      }
    },
    paginationSearch: {
      get () {
        return this.$store.state.product.paginationSearch
      },
      set (paginationSearch) {
        this.$store.commit('product/setPaginationSearch', paginationSearch)
      }
    },
    notifications () {
      return this.$store.state.user.notifications
    },
    productsSearch () {
      return this.$store.state.product.productsSearch
    },
    searchKey: {
      get () {
        return this.$store.state.product.searchKey
      },
      set (payload) {
        this.$store.commit('product/setSearchKey', payload)
      }
    },
    notificationCount () {
      return this.$store.state.user.notificationCount
    },
    cartCount () {
      return this.$store.getters['cart/cartCount']
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted () {
    if (this.$route.name === 'Search') {
      this.$refs.mobileInputSearch.focus()
    }
    this.$store.dispatch('user/getNotificationCount')
    this.$store.commit('product/setSearchKey', this.$route.query.searchKey || '')
  }
}
</script>

<style lang="scss">
@import "@/scss/respoinsive";
@include tablet () {
  .flagSearchHeader:nth-child(2n + 2) {
    .product-card__info {
      background-color: #eff9f8;
    }
  }
}
.header-placeholder {
  height: 116px;
  width: 100%;
  display: none;
  &--active {
    display: block;
  }
}
  .header {
    &__site {
        background-color: #59C6BC;
        margin-bottom: 30px;
        @include mobile() {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 70px;
            z-index: 9;
        }
        &--sticky {
          @include tablet {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            z-index: 999;
            .header__wrapper {
              padding: 8px 0;
            }
          }
        }
    }
    &__logo {
      svg {
        width: 100%; max-width: 120px
      }
        @include mobile() {
            display: none;
        }
    }
    &__seach-box {
        position: relative;
        flex: 1 1 60%;
        max-width: 60%;
        width: 60%;
        background-color: #ffffff;
        border-radius: 15px;
        padding: 0 24px 0 50px;
        @include tablet() {
            display: none;
        }
        svg {
            position: absolute;
            top: 50%;
            left: 16px;
            transform: translateY(-50%);
        }
        input {
            width: 100%;
            height: 36px;
            line-height: 36px;
            border: none;
            background-color: transparent;
            padding: 0;
        }
    }
    &__wrapper {
        position: relative;
        display: flex;
        align-items: center;
        padding-top: 26px;
        padding-bottom: 26px;
        transition: all 0.3s;
        @include mobile() {
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }
    &__nav-mobile {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 0;
        margin-left: auto;
        @include tablet {
          display: none;
        }
        &__item {
            margin-left: 16px;
            &:first-child {
                margin-left: 0;
            }
        }
        &__action {
            transition: all .3s;
            &:hover {
                opacity: 0.75;
            }
            &:active {
                opacity: 0.5;
            }
            img {
                display: block;
            }
        }
        &__nav {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;
            &__item {
                width: 100%;
            }
            &__action {
                text-decoration: none;
                text-align: center;
                padding: 16px;
                display: block;
                color: #ffffff;
                transition: all .3s;
                &:hover {
                    text-decoration: none;
                    opacity: 0.75;
                }
                &:active, &:disabled {
                    opacity: 0.5;
                }
                &:focus {
                    outline: none;
                }
            }
        }
        &__panel {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #EFF9F8;
            width: 100%;
            height: 100%;
            z-index: 9999;
            display: flex;
            flex-direction: column;
            &__close {
                cursor: pointer;
                transition: all .3s;
                &:hover {
                    opacity: 0.75;
                }
                &:active {
                    opacity: 0.5;
                }
            }
            &__header {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 45px 30px;
                img {
                    display: block;
                }
            }
            &__content {
                flex: 1;
                display: flex;
                align-items: center;
                overflow-y: auto;
                padding: 24px 0;
            }
        }
    }
    &__nav {
        display: none;
        @include tablet() {
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
        }

        &__item {
            margin-right: 40px;
            display: flex;
            align-items: center;
            &:last-child {
                margin-right: 0;
            }
        }

        &__action {
            color: #ffffff;
            text-decoration: none;
            display: block;
            text-align: center;
            text-transform: uppercase;
            &:hover {
                color: #003B35;
                opacity: 0.75;
            }
        }
    }
}
.cart-icon {
  position: relative;
  .cart-count {
    position: absolute;
    top: -6px;
    right: 0;
    text-decoration: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #EB5757;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }
}
.message {
        padding: 20px;
        border-bottom: 1px solid #59C6BC;
        transition: all 0.3s;
        cursor: pointer;
        background-color: #eff9f8;
        color: #003b35;
        &--is-read {
          background-color: #ffffff;
        }
        &:hover {
          opacity: 0.75;
        }
    }
    .marked-is-read {
        font-size: 14px;
        font-weight: 500;
        color: #B93D00;
        margin-bottom: 14px;
        cursor: pointer;
        transition: all 0.3s;
        span {
          display: block;
        }
        &:hover {
            opacity: 0.75;
        }
        &:active {
            opacity: 0.5;
        }
    }
</style>
