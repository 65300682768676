<template>
    <div class="product-card" v-if="product.isShow" :class="[inCart(product) ? 'product-card--disable' : '']">
      <div class="product-card__info">
        <NewIcon v-if="product.isNew" class="product-card__new"/>
        <div class="product-card__name" v-if="product.productName">{{product.productName}} <span v-if="product.productSize">{{product.productSize}}</span></div>
        <div class="product-card__sku pr--sm--22">SKU: <span v-if="product.productCode">{{product.productCode}}</span></div>
        <div class="product-card__active-ingredient pr--sm--22" @click="detailDialogVisible = true">
          <span>Thành phần hoạt chất</span>
           <ArrowDownIcon class="icon"/>
        </div>
        <div class="product-card__info__price">
          <div class="product-card__price">{{product.price.toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}}</div>
          <a class="product-card__detail-action" :href="product.link" target="_blank">
            <span>Chi tiết SP</span>
            <GoIcon class="icon"/>
          </a>
          <el-dialog
            title="Thành phần hoạt chất"
            class="custom"
            :visible.sync="detailDialogVisible">
            <div v-html="product.description"></div>
          </el-dialog>
        </div>
      </div>
      <div class="product-card__quality">
        <div class="product-card__dropdown">
          <div class="product-card__dropdown__header">
            <span>1 Hộp</span>
          </div>
        </div>
      </div>
      <div class="product-card__action" v-on:click="addToCardHandle(product)">
        <CartIcon/>
      </div>
    </div>
</template>

<script>
import ArrowDownIcon from '@/assets/arrow-down.svg'
import CartIcon from '@/assets/cart-icon.svg'
import GoIcon from '@/assets/go-icon.svg'
import NewIcon from '@/assets/product-new-icon.svg'

export default {
  components: {
    ArrowDownIcon,
    CartIcon,
    GoIcon,
    NewIcon
  },
  data () {
    return {
      quantity: 1,
      detailDialogVisible: false
    }
  },
  props: {
    product: Object
  },
  computed: {
  },
  methods: {
    inCart (product) {
      return !(this.$store.state.cart.cartItems.findIndex(item => item.productCode === product.productCode) === -1)
    },
    addToCardHandle (product) {
      if (this.$store.getters['auth/customerIsSelected']) {
        this.$store.dispatch('cart/addItem', { ...product, quantity: this.quantity })
      } else {
        this.$notify.error({
          duration: 1000,
          title: 'Thông báo!',
          message: 'Vui lòng chọn khách hàng'
        })
      }
    },
    showActiveIngredientHandle () {
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/respoinsive";
  .el-dialog__wrapper.custom .el-dialog__header{
    background-color: #59C6BC;
  }
  .product-card {
    display: flex;
    height: 100%;
    &--disable {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .product-card__new {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
  }
  .product-card__info {
    position: relative;
    border: 1px solid #59C6BC;
    border-radius: 15px 0px 0px 15px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
    padding-right: 3px;
    @include tablet() {
        padding: 16px;
    }
    &__price {
        position: absolute;
        text-align: center;
        bottom: 8px;
        right: 8px;
    }
  }
  .product-card__name {
    font-size: 14px;
    margin-bottom: 4px;
  }
  .product-card__sku {
    font-size: 12px;
    margin-bottom: 2px;
  }
  .product-card__price {
    font-size: 20px;
    line-height: 1.1;
    color: #B93D00;
  }
  .product-card__action {
    display: flex;
    align-items: center;
    background-color: #F36F20;
    border-radius: 0px 15px 15px 0px;
    border-left: solid 1px #EFF9F8;
    cursor: pointer;
    transition: all 0.3s;
    padding: 12px;
    @include tablet() {
      padding: 16px;
    }
    &:hover {
        opacity: 0.75;
    }
  }
  .product-card__active-ingredient {
    display: flex;
    align-items: center;
    font-size: 12px;
    .icon {
      margin-left: 8px;
    }
  }
  .product-card__quality {
    @include mobile() {
      max-width: 70px;
    }
  }
  .product-card__detail-action {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .icon {
      margin-left: 8px;
    }
  }
  .product-card__dropdown {
    height: 100%;
    position: relative;
    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F36F20;
        height: 100%;
        color: #000000;
        cursor: pointer;
        transition: all 0.3s;
        padding: 12px 8px;
        overflow: hidden;
        text-align: center;
        @include tablet() {
          padding: 16px 8px;
        }
        &:hover {
            opacity: 0.75;
        }
    }
    &__nav {
        display: none;
        &__item {

        }
    }
  }
</style>
